var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"content-header"},[_c('b-col',{staticClass:"content-header-left mb-2",attrs:{"cols":"12","md":"12"}},[_c('b-row',{staticClass:"breadcrumbs-top"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"custom-header-title"},[_vm._v(" Editing "+_vm._s(_vm.callLog.stringID)+" ")]),_c('p',{staticClass:"log_info"},[_vm._v(" Created by "+_vm._s(_vm.callLog.createdBy ? _vm.callLog.createdBy.name : '')+" on "+_vm._s(_vm.dateFormatWithTime(_vm.callLog.createdAt))+" "),_c('br'),_vm._v("Last updated on "+_vm._s(_vm.dateFormatWithTime(_vm.callLog.updatedAt))+" ")])])],1)],1)],1),_c('validation-observer',{ref:"callLogUpdateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"md-6"}},[_c('b-form-group',{attrs:{"label":"Customer Name*","label-for":"h-call-log-customerName","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Customer Name","vid":"customerName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-call-log-customerName","placeholder":"First name and last name","state":(errors.length > 0 || _vm.customerNameValidation) ? false : null,"name":"customerName"},on:{"input":function($event){_vm.customerNameValidation == true ? _vm.customerNameValidation = false : null}},model:{value:(_vm.customerName),callback:function ($$v) {_vm.customerName=$$v},expression:"customerName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.customerNameValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.customerNameError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"cols":"md-6"}},[_c('b-form-group',{attrs:{"label":"Mobile Number*","label-for":"h-call-log-customerContact","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Mobile Number","vid":"customerContact","rules":"required|digits:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-call-log-customerContact","placeholder":"(65) Enter 8-digits number","state":(errors.length > 0 || _vm.customerContactValidation) ? false : null,"name":"customerContact"},on:{"input":function($event){_vm.customerContactValidation == true ? _vm.customerContactValidation = false : null}},model:{value:(_vm.customerContact),callback:function ($$v) {_vm.customerContact=$$v},expression:"customerContact"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.customerContactValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.customerContactError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"cols":"md-6"}},[_c('validation-provider',{attrs:{"name":"Type of Call","vid":"typeOfCall","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type of Call*","label-for":"h-call-log-typeOfCall","label-cols-md":"3","state":(errors.length > 0 || _vm.typeOfCallValidation) ? false : null}},[_c('v-select',{attrs:{"id":"h-call-log-typeOfCall","label":"name","options":_vm.callLogTypes,"reduce":function (name) { return name._id; },"clearable":false},on:{"input":_vm.setNatureOfCalls},model:{value:(_vm.typeOfCall),callback:function ($$v) {_vm.typeOfCall=$$v},expression:"typeOfCall"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.typeOfCallValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.typeOfCallError)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"cols":"md-6"}},[_c('validation-provider',{attrs:{"name":"Nature of Call","vid":"natureOfCall","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nature of Call*","label-for":"h-call-log-natureOfCall","label-cols-md":"3","state":(errors.length > 0 || _vm.natureOfCallValidation) ? false : null}},[_c('v-select',{attrs:{"id":"h-call-log-natureOfCall","label":"title","options":_vm.callNatures,"reduce":function (title) { return title.code; },"clearable":false},on:{"input":function($event){_vm.natureOfCallValidation == true ? _vm.natureOfCallValidation = false : null}},model:{value:(_vm.natureOfCall),callback:function ($$v) {_vm.natureOfCall=$$v},expression:"natureOfCall"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.natureOfCallValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.natureOfCallError)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"cols":"md-9"}},[_c('b-form-group',{attrs:{"label":"Details and Remarks","label-for":"h-call-log-remarks","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Details and Remarks","vid":"remarks","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"h-call-log-remarks","placeholder":"Details and Remarks","rows":"3","state":(errors.length > 0 || _vm.remarksValidation) ? false : null,"name":"remarks"},on:{"input":function($event){_vm.remarksValidation == true ? _vm.remarksValidation = false : null}},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.remarksValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.remarksError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-3"}}),_c('b-col',{attrs:{"cols":"md-6"}},[_c('validation-provider',{attrs:{"name":"Duty Officer","vid":"dutyOfficer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Duty Officer*","label-for":"h-call-log-dutyOfficer","label-cols-md":"3","state":(errors.length > 0 || _vm.dutyOfficerValidation) ? false : null}},[_c('v-select',{attrs:{"id":"h-call-log-dutyOfficer","label":"name","options":_vm.dutyOfficers,"reduce":function (name) { return name._id; },"clearable":false},on:{"input":function($event){_vm.dutyOfficerValidation == true ? _vm.dutyOfficerValidation = false : null}},model:{value:(_vm.dutyOfficer),callback:function ($$v) {_vm.dutyOfficer=$$v},expression:"dutyOfficer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.dutyOfficerValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.dutyOfficerError)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"md-6"}})],1)],1),_c('b-nav',{staticClass:"wrap-border save-nav"},[_c('li',{staticClass:"nav-item ml-auto"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"flat-primary"},on:{"click":_vm.cancelEdit}},[_vm._v(" Cancel ")])],1),_c('li',{staticClass:"nav-item mr-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"success","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Save Changes")])],1)],1)])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }